import React, { useState, useEffect } from "react";
import Guid from "../accerts/svg/guid.svg";
import mathHeader from "../accerts/svg/mathbaner.svg";
import footer from "../accerts/svg/footer.svg";
import { useParams } from "react-router-dom";
import BasicReport from "./components/ScoreREports/BasicReport";
import loaderGif from "../accerts/gifs/LoaderGif.gif";
import Timer from "../accerts/gifs/timerGIf.gif";
import { CONFIG_VARS } from "./constants/configaration";
export const OlympaidReport = () => {
  const { crnId, eventId } = useParams();
  const [reportData, setReportData] = useState(null);
  const [advanceReport, setAdvanceReport] = useState(null);
  const [progressordata, setProgressordata] = useState({});
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState();
  const [advaceLoader, setAdvaceLoader] = useState(false);

  const PostAdvancereport = async (eventId, crnId, reportType) => {
    setAdvaceLoader(true);
    try {
      const res = await fetch(
        `${CONFIG_VARS.baseUrl}?event_id=${eventId}&crn_id=${crnId}&event=${reportType}`,
        {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Product-Id": "100",
            "Tenant-Id": "99999",
          },
          body: "", // Empty body as per your curl command
        }
      );

      if (res.status === 201) {
        const data = await res.json();
        console.log(data); // Log the response data if needed
        fetchReports(crnId, eventId);
      }
    } catch (err) {
      console.error("Error creating advanced report:", err);
    } finally {
      setAdvaceLoader(false);
    }
  };
  const fetchReports = async (crnId, eventId) => {
    setLoader(true);
    setLoader(true);
    const cacheBuster = new Date().getTime();
    const res = await fetch(
      `${CONFIG_VARS.S3buket}/${crnId}_${eventId}.json?cb=${cacheBuster}`
    );
    console.log(res.status);
    if (res?.status === 200) {
      const data = await res?.json();
      setReportData(data);
      setProgressordata(data?.basic_report?.section_wise_marks);

      const examSubmittedAt = new Date(data?.exam_submitted_at);
      const currentTime = new Date();
      const timeDifference = currentTime - examSubmittedAt;

      if (timeDifference > 2 * 60 * 60 * 1000) {
        if (!data?.advanced_report?.length > 0) {
          PostAdvancereport(eventId, crnId, "matholympiad");
        } else {
          setAdvanceReport(
            Array.isArray(data?.advanced_report) ? data.advanced_report : []
          );
        }
      }
      setLoader(false);
    } else if (res?.status !== 200) {
      setError("Reports are not available yet ..!");
      setLoader(false);
    }
  };
  useEffect(() => {
    fetchReports(crnId, eventId);
  }, [crnId, eventId]);
  const getColor = (score) => {
    const roundedScore = Math.round(score);
    if (roundedScore >= 0 && roundedScore <= 30) {
      return "#B7295A";
    } else if (roundedScore > 30 && roundedScore <= 60) {
      return "#C4A200";
    } else if (roundedScore > 60 && roundedScore <= 80) {
      return "#00966D";
    } else if (roundedScore > 80 && roundedScore <= 100) {
      return "#23628D";
    }
  };
  const roundNumber = (num) => Math.round(num);
  const getStanineColor = (score) => {
    if (score >= 1 && score <= 3) {
      return "#B7295A";
    } else if (score > 3 && score <= 6) {
      return "#C4A200";
    } else if (score > 6 && score <= 8) {
      return "#00966D";
    } else if (score === 9) {
      return "#23628D";
    }
  };

  const getDotDisplay = (score) => {
    if (score >= 0 && score <= 30) {
      return ["block", "none", "none"];
    } else if (score > 30 && score <= 60) {
      return ["none", "block", "none"];
    } else if (score > 60 && score <= 100) {
      return ["none", "none", "block"];
    }
  };
  const formatDate = (dateString) => {
    const options = {
      weekday: "short",
      year: "numeric",
      month: "short",
      day: "numeric",
    };
    return new Date(dateString).toLocaleDateString("en-US", options);
  };
  return (
    <div className="Rep_main_cont">
      <div className="Mathheader">
        <img style={{ width: "100%" }} src={mathHeader} alt="Header" />
        <p className="examName mathExame">
          {reportData?.event_code} {reportData?.level}
        </p>
        <p className="studentName">{reportData?.first_name}</p>
        <p className="schoolid">{reportData?.school_name}</p>
        <p className="date">{formatDate(reportData?.exam_submitted_at)}</p>
        <p className="grade">{reportData?.grade}</p>
      </div>
      {!loader && !error ? (
        <div>
          {!advanceReport && (
            <>
              <BasicReport
                currentExam="math"
                reportData={reportData}
                progressordata={progressordata}
              />
              {advaceLoader && (
                <div className="Rep_timer-cont">
                  <h1>But Wait!</h1>
                  <p className="Rep_timer-des">
                    <span>We are generating a detailed report for you.</span>
                  </p>

                  <img src={Timer} alt="Timer" />
                </div>
              )}
            </>
          )}
          {advanceReport && (
            <>
              {" "}
              <h3 className="Rep_sub-head">Prelim Summary</h3>
              <div className="MT-reports">
                <div className="Mt-reportHeader">
                  <p className="Mt-SkillHeader">Skill</p>
                  <p className="Mt-SubSkillHeader">Sub-Skill</p>
                  <p className="Mt-ScoreHeader">Score %</p>
                  <p className="Mt-StanineHeader">Stanine</p>
                </div>
                {Array.isArray(advanceReport) &&
                  advanceReport.map((skillGroup, index) =>
                    Object?.keys(skillGroup)?.map((skillName) => (
                      <div key={index} className="Mt-reportbody">
                        <div className="Mt-Skillbody">{skillName}</div>
                        <div
                          style={{ background: "#E2E2E2", width: "1px" }}
                        ></div>
                        <div className="Mt-SubSkillcont">
                          {Object?.entries(
                            skillGroup[skillName]?.subskills
                          )?.map(([subSkillName, subSkillData], index) => (
                            <div key={index} className="Mt-inSkillcont">
                              <div className="Mt-subskillbody">
                                {subSkillName?.trim()}
                              </div>
                              <div
                                className="Mt-Scorebody"
                                style={{
                                  color: getColor(subSkillData?.subskill_score),
                                }}
                              >
                                {roundNumber(subSkillData?.subskill_score)}
                              </div>
                            </div>
                          ))}
                        </div>
                        <div
                          style={{ background: "#E2E2E2", width: "1px" }}
                        ></div>
                        <div
                          className="Mt-Staninebody"
                          style={{
                            color: getStanineColor(
                              skillGroup[skillName]?.stanine_score
                            ),
                          }}
                        >
                          {skillGroup[skillName]?.stanine_score}
                        </div>
                      </div>
                    ))
                  )}
              </div>
              <div className="MT-reportsGuide">
                <p>
                  <span>Note:</span> A stanine (“Standard Nine”) score is a way
                  to scale scores on a nine-point scale.
                </p>
                <img src={Guid} alt="Guide" />
              </div>
              <h3 className="Rep_sub-head">Proficiency</h3>
              <div className="MT-reportsProfi">
                <div className="Mt-reportHeader">
                  <p className="Mt-Sub-SkillHeader">Sub-Skill</p>
                  <p className="Mt-medHeader">Low</p>
                  <p className="Mt-medHeader">Med</p>
                  <p className="Mt-medHeader">High</p>
                </div>

                {advanceReport?.map((skillGroup) =>
                  Object?.keys(skillGroup)?.map((skillName) =>
                    Object?.entries(skillGroup[skillName]?.subskills)?.map(
                      ([subSkillName, subSkillData], index) => {
                        const [lowDisplay, midDisplay, highDisplay] =
                          getDotDisplay(subSkillData?.subskill_score);
                        return (
                          <div key={index} className="Mt-reportbody">
                            <div className="Mt-Sub-Skillbody">
                              {subSkillName?.trim()}
                            </div>
                            <div className="Mt-medbody">
                              <div
                                className="Mt-medDotbody"
                                style={{
                                  background: "#B7295A",
                                  display: lowDisplay,
                                }}
                              ></div>
                            </div>
                            <div className="Mt-medbody">
                              <div
                                className="Mt-medDotbody"
                                style={{
                                  background: "#C4A200",
                                  display: midDisplay,
                                }}
                              ></div>
                            </div>
                            <div className="Mt-medbody">
                              <div
                                className="Mt-medDotbody"
                                style={{
                                  background: "#00966D",
                                  display: highDisplay,
                                }}
                              ></div>
                            </div>
                          </div>
                        );
                      }
                    )
                  )
                )}
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="Loader">
          {!error ? (
            <>
              {" "}
              <img src={loaderGif} alt="loading" />
              <h4>Wait we are prepraing your reports....</h4>
            </>
          ) : (
            <div>{error}</div>
          )}
        </div>
      )}
      <img
        style={{ width: "100%", marginTop: "1rem" }}
        src={footer}
        alt="footer"
      />
    </div>
  );
};
