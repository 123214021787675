import React from "react";
import line from "../../../accerts/svg/reportspage/Line24.svg";
import ProgressBar from "./ProgressBar";
import best from "../../../accerts/svg/reportspage/bestSub.svg";
import improve from "../../../accerts/svg/reportspage/improveSub.svg";
import CircleProgressBar from "./circleProgressBar";

const BasicReport = ({ reportData, progressordata, currentExam }) => {
  return (
    <div>
      <h3 className="Rep_sub-head">Overview</h3>
      <div className="Rep_card-cont">
        <div className="Rep_score_card">
          <div>
            <h4 className="Rep_score_cardHead">Score</h4>
            <p>
              {reportData?.basic_report?.marks_obtained}
              <span>/{reportData?.basic_report?.maximum_marks} marks</span>
            </p>
          </div>
          <img className="Rep_score_cardLine" src={line} alt="line" />
          <div className="Rep_score_cardButtom">
            <div>
              <h4 className="Rep_score_cardHead">Accuracy</h4>
              <p className="Rep_score_cardDes">
                {reportData?.basic_report?.accuracy}%
              </p>
            </div>
            <div>
              <h4 className="Rep_score_cardHead">Time Taken</h4>
              <p>{reportData?.basic_report?.time_spent_minutes}</p>
            </div>
            <div>
              <h4 className="Rep_score_cardHead">Avg Time Qs</h4>
              <p>{reportData?.basic_report?.average_time_per_question}</p>
            </div>
          </div>
        </div>
        <CircleProgressBar
          totalMarks={reportData?.basic_report?.maximum_marks}
          correctMarks={reportData?.basic_report?.correct_answers}
          incorrectMarks={reportData?.basic_report?.incorrect_answers}
          unattempted={reportData?.basic_report?.unattempted_questions}
        />
      </div>
      {currentExam !== "math" && currentExam !== "hot" ? (
        <>
          <h3 className="Rep_sub-head">Subject Performance</h3>
          <div className="perform_box">
            <div>
              <div className="perform_webflex">
                <div>
                  <figure className="mb-0">
                    <img src={best} alt="subone" />
                  </figure>
                </div>
                <div className="perform_mobflex">
                  <h3 className="SCRPT-icnhdng no_mb">Best subject</h3>
                  <h4 className="SCRPT-cunt SCRPT-elips">
                    {
                      reportData?.basic_report?.subject_performance?.highest
                        ?.section_name
                    }
                  </h4>
                </div>
              </div>
            </div>
            <div>
              <div className="perform_webflex">
                <div>
                  <figure className="mb-0">
                    <img src={improve} alt="subtwo" />
                  </figure>
                </div>
                <div className="perform_mobflex">
                  <h3 className="SCRPT-icnhdng no_mb">Scope of improvement</h3>
                  <h4 className="SCRPT-cunt SCRPT-elips">
                    {
                      reportData?.basic_report?.subject_performance?.lowest
                        ?.section_name
                    }
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        ""
      )}

      <div className="Rep_Sub_cont">
        <h3 className="Rep_graph_cardHead">Sectionwise Score</h3>
        <div className="Rep_Sub_contIn">
          {Object.keys(progressordata).map((subject) => (
            <div key={subject} className="Rep_Sub_cont-card">
              <h3 className="Rep_Sub_contSubject">{subject}</h3>
              <div className="Rep_progressor-cont">
                <ProgressBar
                  progress={progressordata[subject]?.marks_obtained}
                  total={progressordata[subject]?.total_marks}
                />
                <p>
                  {progressordata[subject]?.marks_obtained}/
                  {progressordata[subject]?.total_marks}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default BasicReport;
