import React, { useState } from "react";
import RightDot from "../../../accerts/svg/reportspage/ArrowRightCircle.svg";
import WrongDot from "../../../accerts/svg/reportspage/ArrowWrongCircle.svg";
import Gray from "../../../accerts/svg/reportspage/graydot.svg";
import CircleProgressBar from "./circleProgressBar";
import persent from "../../../accerts/svg/reportspage/persent.svg";
import up from "../../../accerts/svg/reportspage/up.svg";
import down from "../../../accerts/svg/reportspage/down.svg";
import time from "../../../accerts/svg/reportspage/time.svg";
import target from "../../../accerts/svg/reportspage/target.svg";

const AdvanceReport = ({ reportData }) => {
  const [expandedChapters, setExpandedChapters] = useState({});

  const handleExpand = (index) => {
    setExpandedChapters((prev) => ({
      ...prev,
      [index]: !prev[index],
    }));
  };
  const secondsToMinutes = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    const formattedSeconds =
      remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds;
    return `${minutes}m ${Math.round(formattedSeconds)}s`;
  };

  return (
    <div>
      <h3 className="Rep_sub-head">Section-wise breakdown</h3>
      {reportData?.map((subject, key) => (
        <>
          <h6 className="Rep_sub-head">{subject?.SECTION}</h6>
          <div className="Rep_card-cont">
            <div className="Rep_score_card Rep_score_card3">
              <div className="saparator_v"></div>
              <div className="saparator_h"></div>
              <div className="Rep_score_card2">
                <div className="Rep_score_cardinner">
                  <img src={persent} alt="percentage icon" />
                  <div>
                    <h3 className="Rep_score_inner_title">Score</h3>
                    <p className="Rep_score_inner_marks">
                      {subject?.CORRECT}/{subject?.TOTAL_MARKS}
                    </p>
                  </div>
                </div>
                <div className="Rep_score_cardinner">
                  <img src={target} alt="percentage icon" />
                  <div>
                    <h3 className="Rep_score_inner_title">Accuracy</h3>
                    <p className="Rep_score_inner_marks">{`${
                      subject?.ACCURACY || 0
                    }%`}</p>
                  </div>
                </div>
              </div>
              <div className="Rep_score_card2">
                <div className="Rep_score_cardinner">
                  <img src={time} alt="percentage icon" />
                  <div>
                    <h3 className="Rep_score_inner_title">Total Time taken</h3>
                    <p className="Rep_score_inner_marks">
                      {secondsToMinutes(subject?.TOTAL_TIME_TAKEN)}
                    </p>
                  </div>
                </div>
                <div className="Rep_score_cardinner">
                  <img src={time} alt="percentage icon" />
                  <div>
                    <h3 className="Rep_score_inner_title">
                      Avg. time/ Question
                    </h3>
                    <p className="Rep_score_inner_marks">
                      {secondsToMinutes(subject?.AVG_TIME_TAKEN)}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <CircleProgressBar
              totalMarks={subject?.TOTAL_MARKS}
              correctMarks={subject?.CORRECT}
              incorrectMarks={subject?.INCORRECT}
            />
          </div>
          <div className="AdRp-main-cont">
            <div className="AdRp-header-cont">
              <p className="AdRp-header-contp1">Chapter</p>
              <p className="AdRp-header-contp2">Total</p>
              <p className="AdRp-header-contp3">Correct/Wrong/Unattempted</p>
              <p className="AdRp-header-contp4">Marks Achieved</p>
            </div>
            {subject?.chapters?.map((chapter, index) => {
              const chapterNameWords = chapter?.CHAPTER.split(" ");
              const isLongChapterName = chapterNameWords?.length > 2;
              const chapterStyle = {
                fontSize: isLongChapterName ? "12px" : "inherit",
              };

              const performanceStyle = {
                color:
                  chapter.PERFORMANCE_METRICS === "Keep it up"
                    ? "#2CBE6A"
                    : "#FF5353",
                marginBottom: isLongChapterName ? "1rem" : "0rem",
              };
              return (
                <div key={index} className="AdRp-bodyitems">
                  <div className="AdRp-bodyitems1">
                    <h3 style={chapterStyle}>{chapter?.CHAPTER}</h3>
                    <p style={performanceStyle}>
                      {chapter?.PERFORMANCE_METRICS}
                    </p>
                  </div>
                  <div className="AdRp-bodyitems2">
                    {chapter?.TOTAL_QUESTIONS_ASKED}
                  </div>
                  <div className="AdRp-bodyitems3">
                    <img src={RightDot} alt="Correct Icon" />{" "}
                    <p>{chapter?.QUESTION_STATUS_CORRECT}</p>
                    <img src={WrongDot} alt="Wrong Icon" />{" "}
                    <p>{chapter?.QUESTION_STATUS_WRONG}</p>
                    <img src={Gray} alt="Unattempted Icon" />{" "}
                    <p>{chapter?.QUESTION_STATUS_UNATTEMPTED}</p>
                  </div>
                  <div className="AdRp-bodyitems4">
                    <p>
                      <span>{chapter?.MARKS_SCORED}</span>/
                      {chapter?.TOTAL_MARKS}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
          {subject.chapters?.map((chapter, index) => {
            const chapterNameWords = chapter.CHAPTER.split(" ");
            const isLongChapterName = chapterNameWords.length > 2;
            const chapterStyle = {
              fontSize: isLongChapterName ? "12px" : "inherit",
            };

            const performanceStyle = {
              color:
                chapter.PERFORMANCE_METRICS === "Keep it up"
                  ? "#2CBE6A"
                  : "#FF5353",
              marginBottom: isLongChapterName ? "1rem" : "0rem",
            };

            return (
              <div key={index} className="AdRp-main-contMob">
                <div className="AdRp-bodyitems1">
                  <h3 style={chapterStyle}>{chapter.CHAPTER}</h3>
                  <p style={performanceStyle}>{chapter.PERFORMANCE_METRICS}</p>
                  <div className="AdRp-marksCont">
                    <h4>Marks Achieved</h4>
                    <p>
                      <span>{chapter.MARKS_SCORED}</span>/{chapter.TOTAL_MARKS}
                    </p>
                    <img
                      className="AdRp-mob-epandIcons"
                      onClick={() => handleExpand(index)}
                      src={!expandedChapters[index] ? down : up}
                      alt="arrow"
                    />
                  </div>
                </div>
                {expandedChapters[index] && (
                  <div className="AdRp-inMob-cont">
                    <div className="AdRp-header-cont">
                      <p className="AdRp-header-contp2">Qs</p>
                      <p className="AdRp-header-contp3">
                        Correct/Wrong/Unattempted
                      </p>
                    </div>
                    <div className="AdRp-bodyitems">
                      <div className="AdRp-bodyitems2">
                        {chapter.TOTAL_QUESTIONS_ASKED}
                      </div>
                      <div className="AdRp-bodyitems3">
                        <img src={RightDot} alt="Correct Icon" />
                        <p>{chapter.QUESTION_STATUS_CORRECT}</p>
                        <img src={WrongDot} alt="Wrong Icon" />
                        <p>{chapter.QUESTION_STATUS_WRONG}</p>
                        <img src={Gray} alt="Unattempted Icon" />
                        <p>{chapter.QUESTION_STATUS_UNATTEMPTED}</p>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        </>
      ))}

      {/* {report.chapters?.map((chapter, index) => {
          const chapterNameWords = chapter.CHAPTER.split(" ");
          const isLongChapterName = chapterNameWords.length > 2;
          const chapterStyle = {
            fontSize: isLongChapterName ? "12px" : "inherit",
          };

          const performanceStyle = {
            color:
              chapter.PERFORMANCE_METRICS === "Keep it up"
                ? "#2CBE6A"
                : "#FF5353",
            marginBottom: isLongChapterName ? "1rem" : "0rem",
          };

          return (
            <div key={index} className="AdRp-bodyitems">
              <div className="AdRp-bodyitems1">
                <h3 style={chapterStyle}>{chapter.CHAPTER}</h3>
                <p style={performanceStyle}>{chapter.PERFORMANCE_METRICS}</p>
              </div>
              <div className="AdRp-bodyitems2">
                {chapter.TOTAL_QUESTIONS_ASKED}
              </div>
              <div className="AdRp-bodyitems3">
                <img src={RightDot} alt="Correct Icon" />{" "}
                <p>{chapter.QUESTION_STATUS_CORRECT}</p>
                <img src={WrongDot} alt="Wrong Icon" />{" "}
                <p>{chapter.QUESTION_STATUS_WRONG}</p>
                <img src={Gray} alt="Unattempted Icon" />{" "}
                <p>{chapter.QUESTION_STATUS_UNATTEMPTED}</p>
              </div>
              <div className="AdRp-bodyitems4">
                <p>
                  <span>{chapter.MARKS_SCORED}</span>/{chapter.TOTAL_MARKS}
                </p>
              </div>
            </div>
          );
        })}
      </div>

      {report.chapters?.map((chapter, index) => {
        const chapterNameWords = chapter.CHAPTER.split(" ");
        const isLongChapterName = chapterNameWords.length > 2;
        const chapterStyle = {
          fontSize: isLongChapterName ? "12px" : "inherit",
        };

        const performanceStyle = {
          color:
            chapter.PERFORMANCE_METRICS === "Keep it up"
              ? "#2CBE6A"
              : "#FF5353",
          marginBottom: isLongChapterName ? "1rem" : "0rem",
        };

        return (
          <div key={index} className="AdRp-main-contMob">
            <div className="AdRp-bodyitems1">
              <h3 style={chapterStyle}>{chapter.CHAPTER}</h3>
              <p style={performanceStyle}>{chapter.PERFORMANCE_METRICS}</p>
              <div className="AdRp-marksCont">
                <h4>Marks Achieved</h4>
                <p>
                  <span>{chapter.MARKS_SCORED}</span>/{chapter.TOTAL_MARKS}
                </p>
                <img
                  className="AdRp-mob-epandIcons"
                  onClick={() => handleExpand(index)}
                  src={!expandedChapters[index] ? down : up}
                  alt="arrow"
                />
              </div>
            </div>
            {expandedChapters[index] && (
              <div className="AdRp-inMob-cont">
                <div className="AdRp-header-cont">
                  <p className="AdRp-header-contp2">Qs</p>
                  <p className="AdRp-header-contp3">
                    Correct/Wrong/Unattempted
                  </p>
                </div>
                <div className="AdRp-bodyitems">
                  <div className="AdRp-bodyitems2">
                    {chapter.TOTAL_QUESTIONS_ASKED}
                  </div>
                  <div className="AdRp-bodyitems3">
                    <img src={RightDot} alt="Correct Icon" />
                    <p>{chapter.QUESTION_STATUS_CORRECT}</p>
                    <img src={WrongDot} alt="Wrong Icon" />
                    <p>{chapter.QUESTION_STATUS_WRONG}</p>
                    <img src={Gray} alt="Unattempted Icon" />
                    <p>{chapter.QUESTION_STATUS_UNATTEMPTED}</p>
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      })} 
      </div> */}
    </div>
  );
};

export default AdvanceReport;
